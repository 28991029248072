<template>
  <div v-if="visible" class="toast" :class="typeClass">{{ message }}</div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      message: '',
      type: 'info', // 默认为 info 类型，可根据需要扩展其他类型
    };
  },
  methods: {
    show(message, type = 'info', duration = 3000) {
      this.message = message;
      this.type = type;
      this.visible = true;

      setTimeout(() => {
        this.hide();
      }, duration);
    },
    hide() {
      this.visible = false;
    },
  },
  computed: {
    typeClass() {
      return `toast-${this.type}`;
    },
  },
};
</script>
<style lang="less" scoped src="./index.less">

</style>