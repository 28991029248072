// 引入
import Vue from "vue";
import Router from "vue-router"

// 使用
Vue.use(Router)

// 创建路由规则数据
const routes=[
    {
        path:"/",
        component:()=>import("@/view/home/index.vue")
    },
    {
        path:"/about",
        component:()=>import("@/view/about/index.vue")
    },
    {
        path:"/reservat",
        component:()=>import("@/view/reservat/index.vue")
    },
    {
        path:"/patService",
        component:()=>import("@/view/patService/index.vue")
    },
    {
        path:"/bookPlatform",
        component:()=>import("@/view/bookPlatform/index.vue")
    },
    {
        path:"/payPlatform",
        component:()=>import("@/view/payPlatform/index.vue")
    },
    {
        path:"/internetHospital",
        component:()=>import("@/view/internetHospital/index.vue")
    },
    {
        path:"/satisfactSurvey",
        component:()=>import("@/view/satisfactSurvey/index.vue")
    },
    {
        path: "/mobile",
        component:()=>import("@/view_m/mobile/index.vue")
    }
]

// 生成路由对象
const router=new Router({
    routes:routes,
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }; // 将滚动条回到顶部
    },
})

router.beforeEach((to, from, next) => {
    // 检查跳转的路由是否存在于 routes 配置中
    const routeExists = routes.some(route => route.path == to.path);
    // 如果跳转的路由不存在，重定向回首页
    if (!routeExists) {
      localStorage.setItem("activeIndex", 0)
      next('/');
    } else {
      // 否则正常跳转
      next();
    }
  });

export default router


