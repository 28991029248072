import Vue from 'vue'
import App from './App.vue'
// 引入路由对象
import router from "./router"
import Toast from './components/Toast/index.vue';
import './common/css/index.less'

const toastInstance = new Vue({
  render: (h) => h(Toast),
}).$mount();

document.body.appendChild(toastInstance.$el);

Vue.prototype.$toast = {
  show(message, type = 'info', duration = 3000) {
    toastInstance.$children[0].show(message, type, duration);
  },
};


Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
